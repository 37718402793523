'use client'

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { TenantsContextType } from '@/types/index';
import { Tenant } from '@prisma/client';

const TenantsContext = createContext<TenantsContextType | undefined>(undefined);

interface TenantsProviderProps {
  children: ReactNode;
  initialTenants: Tenant[];
}

export const TenantsProvider: React.FC<TenantsProviderProps> = ({ children, initialTenants }) => {
  const [tenants, setTenants] = useState<Tenant[]>(initialTenants);
  const [closeTenants, setCloseTenants] = useState<Tenant[]>([]);

  return (
    <TenantsContext.Provider value={{ 
      tenants,
      setTenants,
      closeTenants, 
      setCloseTenants, 
    }}>
      {children}
    </TenantsContext.Provider>
  );
};

export const useTenants = (): TenantsContextType => {
  const context = useContext(TenantsContext);
  if (context === undefined) {
    throw new Error('useTenants must be used within an TenantsProvider');
  }
  return context;
};